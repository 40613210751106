import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthService } from '../no-auth/services/auth.service';
import { BehaviorSubject, combineLatest, map, Observable, take } from 'rxjs';

type MenuSwitcher = 'customer' | 'carrier';

interface MenuSwitcherItem {
  name: string;
  menu: MenuSwitcher;
  matIcon: string;
}

@Component({
  selector: 'vpfe-side-nav',
  templateUrl: './side-nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  private authService = inject(AuthService);
  public carrierUserInfo$ = this.authService.carrierUserInfo$;
  public customerUserInfo$ = this.authService.customerUserInfo$;
  private sideNavType$$ = new BehaviorSubject<MenuSwitcher | null>('carrier');
  public sideNavType$ = this.sideNavType$$.asObservable();
  public menuSwitcher$: Observable<MenuSwitcherItem[]> = combineLatest([
    this.customerUserInfo$,
    this.carrierUserInfo$,
  ]).pipe(
    map(([customerUserInfo, carrierUserInfo]) => {
      const allowedMenus: MenuSwitcherItem[] = [];
      if (carrierUserInfo) {
        allowedMenus.push({
          name: 'Carrier',
          menu: 'carrier',
          matIcon: 'local_shipping',
        });
      }
      if (customerUserInfo) {
        allowedMenus.push({
          name: 'Customer',
          menu: 'customer',
          matIcon: 'storefront',
        });
      }
      return allowedMenus;
    }),
  );
  constructor() {
    this.setInitialMenu();
  }

  public setSideNavType(type: MenuSwitcher, event: MouseEvent) {
    event.stopPropagation();
    this.sideNavType$$.next(type);
  }

  public trackByFn(_: number, item: MenuSwitcherItem) {
    return item.menu;
  }

  private setInitialMenu() {
    this.authService.userInfo$.pipe(take(1)).subscribe((userInfo) => {
      if (userInfo?.carrierUserInfo) {
        this.sideNavType$$.next('carrier');
      } else if (userInfo?.customerUserInfo) {
        this.sideNavType$$.next('customer');
      }
    });
  }
}
