<mat-form-field class="w-full" (click)="$event.stopPropagation()">
  <mat-label>Switch Customer</mat-label>
  <mat-select #customerSelect [formControl]="customerPicker" class="w-full">
    <mat-select-trigger>
      <ng-container *ngIf="(customers$ | async)?.[0] as customer">
        {{ customer.name }}
      </ng-container>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Search"
        noEntriesFoundLabel="No match found"
        [formControl]="customerSearch"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let customer of customers$ | async; let i = index; trackBy: trackByFn" [value]="customer.id">
      {{ customer.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
