import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('src/app/modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'carrier',
    loadChildren: () => import('src/app/modules/carrier/carrier.module').then((m) => m.CarrierModule),
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'customer',
    loadChildren: () => import('src/app/modules/customer/customer.module').then((m) => m.CustomerModule),
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
