import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerSwitcherService {
  public customers$: Observable<{ id: string; name: string }[]>;

  constructor(private httpClient: HttpClient) {
    this.customers$ = this.httpClient
      .get<{
        companies: {
          id: string;
          name: string;
        }[];
      }>(`${environment.api}/v1/external/broker_portal/customers`)
      .pipe(
        map((v) => v.companies || []),
        shareReplay(1),
      );
  }

  public switchCustomer(companyId: string) {
    return lastValueFrom(
      this.httpClient.put(`${environment.api}/v1/external/broker_portal/internal/swap`, {
        companyId,
      }),
    );
  }
}
