import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

export interface BreadcrumbEntry {
  label: string;
  url: null | string | string[];
}

const defaultTitle = 'VPF Enterprise';
const defaultShortTitle = 'VFP';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private titleService = inject(Title);
  private breadcrumbs$$ = new BehaviorSubject<BreadcrumbEntry[]>([]);
  public breadcrumbs$ = this.breadcrumbs$$.asObservable().pipe(debounceTime(100), shareReplay(1));

  constructor() {}

  public setBreadcrumbs(breadcrumbs: BreadcrumbEntry[] | null) {
    this.breadcrumbs$$.next(breadcrumbs || []);
    if (breadcrumbs && breadcrumbs.length) {
      const title = breadcrumbs.map((breadcrumb) => breadcrumb.label).join(' > ');
      if (title.length) {
        this.titleService.setTitle(`${defaultShortTitle} > ${title}`);
      } else {
        this.titleService.setTitle(defaultTitle);
      }
    } else {
      this.titleService.setTitle(defaultTitle);
    }
  }
}
