import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../no-auth/services/auth.service';
import { SnackBarService } from '../../no-auth/services/snackbar.service';
import { combineLatest, lastValueFrom, Observable, skip } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { FusedObservable } from '../../shared/utilities/fusedObservable';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CustomerSwitcherService } from '../customer-switcher.service';

const MAX_CUSTOMERS_TO_SHOW = 50;

@Component({
  selector: 'vpfe-customer-switcher',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
  ],
  templateUrl: './customer-switcher.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSwitcherComponent {
  private customerSwitcherService = inject(CustomerSwitcherService);
  private authService = inject(AuthService);
  private snackBar = inject(SnackBarService);
  @ViewChild('customerSelect') private customerSelect: MatSelect;
  public customerSearch = new FormControl<string>(null);
  public customerPicker = new FormControl<string>(null);
  public customers$: Observable<{ id: string; name: string }[]>;

  constructor() {
    this.doSetup();
    this.initializeSwitcherForm();
  }

  private doSetup() {
    const myCustomer$: Observable<{ id: string; name: string }> = combineLatest([
      this.customerSwitcherService.customers$,
      this.authService.customerUserInfo$,
    ]).pipe(
      map(([customers, userInfo]) => {
        return (customers || []).find((c) => c.id === userInfo?.BrokerAccountId);
      }),
    );
    const searchedCustomers$ = new FusedObservable<{ id: string; name: string }>(
      this.customerSwitcherService.customers$,
      this.customerSearch.valueChanges,
      ['id', 'name'],
    ).fused$.pipe(
      map((data) => {
        return data.slice(0, Math.min(data.length, MAX_CUSTOMERS_TO_SHOW));
      }),
    );
    this.customers$ = combineLatest([searchedCustomers$, myCustomer$]).pipe(
      map(([searchedCustomers, myCustomer]) => {
        let results = [];
        if (searchedCustomers) {
          results = searchedCustomers;
        }
        if (myCustomer) {
          results = [
            {
              ...myCustomer,
            },
            ...results,
          ];
        }
        return results;
      }),
    );
  }

  private async initializeSwitcherForm() {
    this.customerPicker.valueChanges
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(),
        filter((customerId) => !!customerId),
        skip(1), // because we are going to change it on the user info listener below
      )
      .subscribe({
        next: (customerId) => {
          this.switchCustomer(customerId);
        },
      });
    this.authService.customerUserInfo$.pipe(takeUntilDestroyed()).subscribe({
      next: (userInfo) => {
        if (userInfo) {
          this.customerPicker.setValue(userInfo.BrokerAccountId, { emitEvent: true });
        }
      },
    });
  }

  private async switchCustomer(customerId: string) {
    try {
      this.customerSelect.close();
      const userInfo = await lastValueFrom(
        this.authService.customerUserInfo$.pipe(
          filter((u) => !!u),
          take(1),
        ),
      );
      if (!userInfo || userInfo.BrokerAccountId === customerId) {
        return;
      }
      await this.customerSwitcherService.switchCustomer(customerId);
      this.snackBar.showMessage('Customer updated');
      this.authService.loadUserInfo().then();
    } catch (e) {}
  }

  public trackByFn(_: number, item: { id: string; name: string }) {
    return item.id;
  }
}
