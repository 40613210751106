<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="over"
    class="w-full lg:w-1/2 xl:w-1/3 bg-white max-h-screen"
    [autoFocus]="false"
    (keydown.escape)="sidenav.close()"
  >
    <vpfe-side-nav (click)="sidenav.close()"></vpfe-side-nav>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-screen">
      <div class="h-6 flex bg-white z-10" style="min-height: 48px">
        <div class="w-8 flex items-center justify-center">
          <mat-icon class="cursor-pointer" (click)="sidenav.open()" *ngIf="isLoggedIn$ | async">menu</mat-icon>
        </div>
        <div class="flex-1 flex items-center">
          <img src="/assets/logos/walmart.svg" class="h-3" />
          <vpfe-breadcrumb class="ml-1"></vpfe-breadcrumb>
        </div>
      </div>
      <div class="flex-1 flex flex-col">
        <router-outlet class="hidden"></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<vpfe-versioning></vpfe-versioning>
