import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './shared/material/material.module';
import { environment } from '../environments/environment';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SideNavCarrierComponent } from './side-nav/side-nav-carrier/side-nav-carrier.component';
import { AuthInterceptorService } from './shared/services/auth-interceptor.interceptor';
import { BreadcrumbComponent } from './no-auth/components/breadcrumb/breadcrumb.component';
import { SideNavCustomerComponent } from './side-nav/side-nav-customer/side-nav-customer.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { VersioningComponent } from './no-auth/components/versioning/versioning.component';
import { CarrierSwitcherComponent } from './internal-switchers/company-switcher/carrier-switcher.component';
import { InternalDirective } from './no-auth/directives/internal.directive';
import { CustomerSwitcherComponent } from './internal-switchers/customer-switcher/customer-switcher.component';

const firebaseConfig = {
  projectId: 'lohi-f5606',
  appId: '1:471883051371:web:fe51d013d0c96804f69c2e',
  databaseURL: 'https://lohi-f5606.firebaseio.com',
  storageBucket: 'lohi-f5606.appspot.com',
  locationId: 'us-central',
  apiKey: 'AIzaSyC-c8kIxYnYOWHEnTBQiblF1bzFvyBAQZw',
  authDomain: 'lohi-f5606.firebaseapp.com',
  messagingSenderId: '471883051371',
};

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    SideNavCarrierComponent,
    BreadcrumbComponent,
    SideNavCustomerComponent,
    VersioningComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AppRoutingModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
      colorScheme: ['blue', 'teal', 'gray', 'gray', 'red', 'red', 'red'],
      enableSourceMaps: !environment.production,
    }),
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !window.location.href.includes('localhost'),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CarrierSwitcherComponent,
    InternalDirective,
    CustomerSwitcherComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
