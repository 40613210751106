<div class="flex flex-col h-full" *ngIf="sideNavType$ | async as sidenavType">
  <div class="flex flex-row justify-between items-center p-3">
    <div></div>
    <span class="flex items-center cursor-pointer text-gray-400"
      >esc&nbsp;<mat-icon class="text-gray-400">close</mat-icon></span
    >
  </div>
  <div class="flex-1">
    <vpfe-side-nav-carrier *ngIf="sidenavType === 'carrier'"></vpfe-side-nav-carrier>
    <vpfe-side-nav-customer *ngIf="sidenavType === 'customer'"></vpfe-side-nav-customer>
  </div>
  <!--  <div class="w-full px-3 my-2" *tdInternal="null">-->
  <!--    <vpfecompany-switcher-shared></vpfecompany-switcher-shared>-->
  <!--  </div>-->
  <!--  <div class="p-3">-->
  <ng-container *ngIf="menuSwitcher$ | async as menuSwitcherItems">
    <div class="flex items-center justify-center h-4 w-full mb-3" *ngIf="menuSwitcherItems.length > 1">
      <div
        *ngFor="let item of menuSwitcherItems; trackBy: trackByFn"
        class="flex items-center justify-center cursor-pointer"
        (click)="setSideNavType(item.menu, $event)"
        [matTooltip]="item.name"
        [class.text-blue-500]="(sideNavType$ | async) === item.menu"
      >
        <mat-icon>{{ item.matIcon }}</mat-icon>
      </div>
    </div>
  </ng-container>
</div>
