<div class="overflow-auto h-full flex flex-col">
  <div class="font-bold mb-1 md:mb-3 mx-3">Carrier Portal</div>
  <div class="flex flex-col flex-1">
    <div class="card-with-shadow-no-radius p-1 md:p-3 mx-3">
      <div class="font-bold leading-loose text-lg p-1 md:p-0">Dispatch Clusters</div>
      <div class="flex flex-col">
        <a
          [routerLink]="['/carrier/autodispatch_cluster']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >My Clusters</a
        >
      </div>
    </div>
    <div class="card-with-shadow-no-radius p-1 md:p-3 mx-3 mt-2">
      <div class="font-bold leading-loose text-lg p-1 md:p-0">Financial</div>
      <div class="flex flex-col">
        <a class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition">Dashboard</a>
        <a class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
          >Completed Loads
        </a>
      </div>
    </div>
    <div class="card-with-shadow-no-radius mt-2 md:mt-3 p-1 md:p-3 mx-3">
      <div class="font-bold leading-loose text-lg p-1 md:p-0">My Fleet</div>
      <div class="flex flex-col">
        <a
          [routerLink]="['/carrier/hitches']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Hitches
        </a>
        <a
          [routerLink]="['/carrier/load_list/pending']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Pending Loads
        </a>
        <a
          [routerLink]="['/carrier/load_list/active']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Active Loads</a
        >
        <a
          [routerLink]="['/carrier/assets']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Assets</a
        >
        <a
          [routerLink]="['/carrier/users']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Users</a
        >
      </div>
    </div>
    <div class="flex-1"></div>
    <vpfe-carrier-switcher *vpfeInternal></vpfe-carrier-switcher>
  </div>
</div>
