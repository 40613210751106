import { ChangeDetectionStrategy, Component, ViewChild, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, lastValueFrom, Observable, skip } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { FusedObservable } from '../../shared/utilities/fusedObservable';
import { CarrierSwitcherService } from '../carrier-switcher.service';
import { AuthService } from '../../no-auth/services/auth.service';
import { SnackBarService } from '../../no-auth/services/snackbar.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const MAX_COMPANIES_TO_SHOW = 50;

@Component({
  selector: 'vpfe-carrier-switcher',
  standalone: true,
  templateUrl: './carrier-switcher.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    NgxMatSelectSearchModule,
  ],
})
export class CarrierSwitcherComponent {
  private companySwapper = inject(CarrierSwitcherService);
  private authService = inject(AuthService);
  private snackBar = inject(SnackBarService);
  @ViewChild('companySelect') private companySelect: MatSelect;
  public companySearch = new FormControl<string>(null);
  public companyPicker = new FormControl<string>(null);
  public companies$: Observable<{ id: string; name: string; billingSubsidiary: string }[]>;

  constructor() {
    this.doSetup();
    this.initializeSwitcherForm();
  }

  private doSetup() {
    const myCompany$: Observable<{ id: string; name: string; billingSubsidiary: string }> = combineLatest([
      this.companySwapper.carriers$,
      this.authService.carrierUserInfo$,
    ]).pipe(
      map(([companies, userInfo]) => {
        return (companies || []).find((c) => c.id === userInfo?.companyId);
      }),
    );
    const searchedCompanies$ = new FusedObservable<{ id: string; name: string; billingSubsidiary: string }>(
      this.companySwapper.carriers$,
      this.companySearch.valueChanges,
      ['id', 'name'],
    ).fused$.pipe(
      map((data) => {
        return data.slice(0, Math.min(data.length, MAX_COMPANIES_TO_SHOW));
      }),
    );
    this.companies$ = combineLatest([searchedCompanies$, myCompany$]).pipe(
      map(([searchedCompanies, myCompany]) => {
        let results = [];
        if (searchedCompanies) {
          results = searchedCompanies;
        }
        if (myCompany) {
          results = [
            {
              ...myCompany,
            },
            ...results,
          ];
        }
        return results;
      }),
    );
  }

  private async initializeSwitcherForm() {
    this.companyPicker.valueChanges
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(),
        filter((companyId) => !!companyId),
        skip(1), // because we are going to change it on the user info listener below
      )
      .subscribe({
        next: (companyId) => {
          this.swapCompany(companyId);
        },
      });
    this.authService.carrierUserInfo$.pipe(takeUntilDestroyed()).subscribe({
      next: (userInfo) => {
        if (userInfo) {
          this.companyPicker.setValue(userInfo.companyId, { emitEvent: true });
        }
      },
    });
  }

  private async swapCompany(companyId: string) {
    try {
      this.companySelect.close();
      const userInfo = await lastValueFrom(
        this.authService.carrierUserInfo$.pipe(
          filter((u) => !!u),
          take(1),
        ),
      );
      if (!userInfo || userInfo.companyId === companyId) {
        return;
      }
      await this.companySwapper.switchCarrier(companyId);
      this.snackBar.showMessage('Carrier updated');
      this.authService.loadUserInfo().then();
    } catch (e) {}
  }

  public trackByFn(index: number, item: { id: string; name: string; billingSubsidiary: string }) {
    return item.id;
  }
}
