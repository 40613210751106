import { ChangeDetectorRef, Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[vpfeInternal]',
  standalone: true,
})
export class InternalDirective implements OnDestroy {
  private hasView = false;
  private destroy$$ = new Subject<void>();
  private isInternal$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.isInternal$ = this.authService.isInternal$;
    this.listenForViewUpdates();
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private listenForViewUpdates() {
    this.isInternal$.pipe(takeUntil(this.destroy$$)).subscribe((isInternal) => {
      if (!isInternal) {
        this.clearView();
        this.cd.markForCheck();
      } else {
        this.clearView();
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
        this.cd.markForCheck();
      }
    });
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      this.cd.markForCheck();
    }
  }
}
