<mat-form-field class="w-full" (click)="$event.stopPropagation()">
  <mat-label>Switch Carrier</mat-label>
  <mat-select #companySelect [formControl]="companyPicker" class="w-full">
    <mat-select-trigger>
      <ng-container *ngIf="(companies$ | async)?.[0] as company">
        {{ company.name }} ({{ company.id }})
        <ng-container *ngIf="company.billingSubsidiary === 'lohi'">[OA]</ng-container>
        <ng-container *ngIf="company.billingSubsidiary === '5F'">[OO]</ng-container>
        <ng-container *ngIf="company.billingSubsidiary === 'none'">[Classic]</ng-container>
      </ng-container>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Search"
        noEntriesFoundLabel="No match found"
        [formControl]="companySearch"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let company of companies$ | async; let i = index; trackBy: trackByFn" [value]="company.id">
      {{ company.name }} ({{ company.id }})
      <span class="font-bold"
        >[<ng-container *ngIf="company.billingSubsidiary === 'lohi'">OA</ng-container>
        <ng-container *ngIf="company.billingSubsidiary === '5F'">OO</ng-container>
        <ng-container *ngIf="company.billingSubsidiary === 'none'">Classic</ng-container>]</span
      ></mat-option
    >
  </mat-select>
</mat-form-field>
