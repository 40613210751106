<div class="overflow-auto h-full flex flex-col">
  <div class="font-bold mb-1 md:mb-3 mx-3">Customer Portal</div>
  <div class="flex flex-col flex-1">
    <div class="card-with-shadow-no-radius p-1 md:p-3 mx-3">
      <div class="font-bold leading-loose text-lg p-1 md:p-0">Dispatch Clusters</div>
      <div class="flex flex-col">
        <a
          [routerLink]="['/', 'customer', 'autodispatch_cluster']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >My Clusters</a
        >
        <a
          [routerLink]="['/', 'customer', 'facility']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Facilities</a
        >
      </div>
    </div>
    <div class="flex-1"></div>
    <vpfe-customer-switcher *vpfeInternal></vpfe-customer-switcher>
  </div>
</div>
