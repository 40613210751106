import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthService } from './no-auth/services/auth.service';
import { RouterStateService } from './no-auth/services/router-state.service';

@Component({
  selector: 'vpfe-root',
  templateUrl: './app.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private authService = inject(AuthService);
  private routerStateService = inject(RouterStateService);
  public isLoggedIn$ = this.authService.isLoggedIn$;
}
