import { HttpBackend, HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../../environments/environment';
import { SnackBarService } from './snackbar.service';

export const getCarrierUserInfo$ = (): Observable<CarrierUserInfo | null> => {
  const authService = inject(AuthService);
  return authService.carrierUserInfo$;
};

const hasCarrierPermission = (userInfo: UserInfo, permission: Permission): boolean => {
  return !!userInfo.carrierUserInfo?.permissions.includes(permission);
};

export type Permission =
  | 'driver'
  | 'admin'
  | 'internal'
  | 'asset-manager'
  | 'safety-manager'
  | 'poc'
  | 'chassis-manager'
  | '5f-tour-dispatcher'
  | 'broker-api'
  | 'tour-load-booker'
  | 'tour-carrier-manager'
  | 'sales'
  | 'tour-dispatcher-manager'
  | 'can-book-from-other-source'
  | 'tour-driver-manager'
  | 'can-view-carrier-kpis'
  | 'can-access-carrier-billing'
  | 'carrier-sourcing'
  | 'remove-holds'
  | 'cancel-completed-loads'
  | 'assign-load-owner'
  | 'otr-netsuite-actions'
  | 'qa-carrier-loads'
  | 'approve-load-charges'
  | 'otr-billing-team';

export interface CarrierUserInfo {
  id: string;
  allowDriverDirections: boolean;
  cdl: string;
  cdlStateAbbreviation: string;
  cdlStateId: string;
  cdlStateName: string;
  companyCanInviteDrivers: boolean;
  driverName: string;
  phoneNumber: string;
  verified: boolean;
  verifiedTimestamp: string;
  companyName: string;
  companyId: string;
  companyOwnerName: string;
  companyOwnerPhone: string;
  permissions: Permission[];
  homebaseLng: number;
  homebaseLat: number;
  allowDriverBooking: boolean;
  allowDriverCreateTruck: boolean;
  allowDriverCreateTrailer: boolean;
  accountRelationship: 'saas' | 'broker' | 'carrier';
  hasDispatcher: boolean;
  hasELDIntegration: boolean;
  showEarningsPrediction: true;
  showOrderPricing: true;
  earningsProgramMember: boolean;
  isMarketplaceEnabled: boolean;
  isYMTEnabled: boolean;
  isLoHiPayMerchant: boolean;
  isLoHiPayConsumer: boolean;
  lohipayDriversAutoApprovePayment: boolean;
  createAssetWithMarketplace: boolean;
  isFiveFTourDispatcher: boolean;
  ffDriverCut: number; // 35% is 0.35
  isCompanyFiveFTourOnly: boolean;
  enableTours: boolean;
  email: string;
  slackMemberId: string;
  isOtrFranchisee: boolean;
  loadFinderUrl: string | null;
  isDrayage: boolean;
  isCompanyCPG: boolean;
  isCompanyTours: boolean;
  isCompanyDomestic: boolean;
  isCompanyImportExport: boolean;
  isEnterpriseCarrier: boolean;
}

export type CustomerType = 'shipper' | 'broker' | 'agent';

export interface CustomerUserInfo {
  internal: boolean;
  userId: string;
  userName: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BrokerAccountId: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BrokerAccountName: string;
  apiDailyRateLimit: number;
  type: CustomerType;
  enableRebateProgram: boolean;
}

export interface UserInfo {
  carrierUserInfo: CarrierUserInfo | null;
  customerUserInfo: CustomerUserInfo | null;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private initialized$$ = new BehaviorSubject<boolean>(false);
  private httpClientNoInterceptors: HttpClient;
  private userInfo$$ = new BehaviorSubject<UserInfo | null>(null);
  public userInfo$ = this.userInfo$$.asObservable().pipe(shareReplay(1));
  public carrierUserInfo$ = this.initialized$$.pipe(
    filter((initialized) => initialized),
    switchMap(() => this.userInfo$$.asObservable()),
    map((userInfo) => userInfo?.carrierUserInfo ?? null),
    shareReplay(1),
  );
  public customerUserInfo$ = this.userInfo$.pipe(
    map((userInfo) => userInfo?.customerUserInfo ?? null),
    shareReplay(1),
  );
  private isLoggedIn$$ = new BehaviorSubject<boolean>(false);

  public isLoggedIn$ = this.initialized$$.pipe(
    filter((initialized) => initialized),
    switchMap(() => this.isLoggedIn$$.asObservable()),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public isInternal$ = this.userInfo$.pipe(
    filter((userInfo) => !!userInfo),
    map((userInfo) => {
      return userInfo?.customerUserInfo?.internal || userInfo?.carrierUserInfo?.permissions?.includes('internal');
    }),
    shareReplay(1),
  );

  constructor(
    httpBackend: HttpBackend,
    private logger: NGXLogger,
    private afAuth: AngularFireAuth,
    private snackbar: SnackBarService,
  ) {
    this.httpClientNoInterceptors = new HttpClient(httpBackend);
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        await this.loadUserInfo();
        const userInfo = this.userInfo$$.value;
        if (!userInfo) {
          this.isLoggedIn$$.next(false);
          this.logout();
          return;
        }
        this.isLoggedIn$$.next(true);
      } else {
        this.isLoggedIn$$.next(false);
      }
      if (!this.initialized$$.value) {
        this.initialized$$.next(true);
      }
    });
  }

  public async loadUserInfo() {
    const [carrierUserInfo, customerUserInfo] = await Promise.all([
      this.getCarrierUserInfo(),
      this.getCustomerUserInfo(),
    ]);
    if (!carrierUserInfo && !customerUserInfo) {
      return this.logout();
    }
    this.userInfo$$.next({
      carrierUserInfo: carrierUserInfo,
      customerUserInfo: customerUserInfo,
    });
  }

  private async getCarrierUserInfo() {
    const token = await lastValueFrom(
      this.afAuth.idToken.pipe(
        filter((t) => !!t),
        take(1),
      ),
    );
    return lastValueFrom(
      this.httpClientNoInterceptors
        .get<CarrierUserInfo>(`${environment.api}/v1/user_info`, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${token}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'x-api-web-app': 'vpfe',
          },
        })
        .pipe(
          catchError((err) => {
            return of(null);
          }),
        ),
    );
  }

  private async getCustomerUserInfo() {
    const token = await lastValueFrom(
      this.afAuth.idToken.pipe(
        filter((t) => !!t),
        take(1),
      ),
    );
    return lastValueFrom(
      this.httpClientNoInterceptors
        .get<CustomerUserInfo>(`${environment.api}/v1/external/broker_portal/user_info`, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${token}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'x-api-web-app': 'broker',
          },
        })
        .pipe(
          catchError((err) => {
            return of(null);
          }),
        ),
    );
  }

  public async logout(redirect = true) {
    await this.afAuth.signOut();
    if (redirect) {
      window.location.pathname = '/';
    }
  }

  public async loginWithPhone(phoneNumber: string, password: string): Promise<boolean> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.post<{ customToken: string }>(
          `${environment.api}/v1/login/password`,
          { phoneNumber, password },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
            },
          },
        ),
      );
      await this.afAuth.signInWithCustomToken(result.customToken);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }

  public async doPasswordReset(resetCode: string, newPassword: string): Promise<string | null> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.post<{ message: string }>(
          `${environment.api}/v1/do_password_reset`,
          {
            resetCode,
            newPassword,
          },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
            },
          },
        ),
      );
      return result.message;
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  }
}
