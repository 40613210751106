import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export const listenForParamChange$ = (paramName: string): Observable<string | null> => {
  const routerStateService = inject(RouterStateService);
  return routerStateService.listenForParamChange$(paramName);
};

export const listenForQueryChange$ = (queryParamName: string): Observable<string> => {
  const routerStateService = inject(RouterStateService);
  return routerStateService.listenForQueryChange$(queryParamName);
};

export interface RouterState {
  params: Params;
  queryParams: Params;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class RouterStateService {
  private backUrl$$ = new BehaviorSubject<string[]>([]);
  private backUrlParams$$ = new BehaviorSubject<Params>({});
  private routerState$$ = new BehaviorSubject<RouterState | null>(null);

  public get routerState$() {
    return this.routerState$$.asObservable().pipe(filter((params) => !!params));
  }

  public get backUrl$() {
    return this.backUrl$$.asObservable();
  }

  public get backUrlParams$() {
    return this.backUrlParams$$.asObservable();
  }

  public listenForParamChange$(paramName: string): Observable<string | null> {
    return this.routerState$.pipe(
      map((state) => state?.params[paramName]),
      distinctUntilChanged(),
    );
  }

  public listenForQueryChange$(queryParamName: string): Observable<string> {
    return this.routerState$.pipe(
      map((state) => state?.queryParams[queryParamName]),
      distinctUntilChanged(),
    );
  }

  constructor(private router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const snapshot = this.router.routerState.snapshot;
      let { url } = snapshot;
      url = url.split('?')[0];
      const { queryParams } = snapshot.root;

      if (this.routerState$$.value) {
        const withoutParams = this.routerState$$.value.url.split('?')[0];
        if (withoutParams !== url) {
          const oldUrl = ['/', ...withoutParams.split('/').filter((segment) => segment !== '')];
          this.backUrl$$.next(oldUrl);
          this.backUrlParams$$.next(this.routerState$$.value.queryParams);
        }
      }

      let state: ActivatedRouteSnapshot = snapshot.root;
      let params = {};
      while (state.firstChild) {
        params = { ...params, ...state.params };
        state = state.firstChild;
      }
      params = { ...params, ...state.params };
      const data = state.data;
      const routerState = {
        params,
        queryParams,
        url,
        data,
      };
      this.routerState$$.next(routerState);
    });
  }
}
