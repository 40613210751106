import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CarrierSwitcherService {
  public carriers$: Observable<{ id: string; name: string; billingSubsidiary: string }[]>;

  constructor(private httpClient: HttpClient) {
    this.carriers$ = this.httpClient
      .get<{ companies: { id: string; name: string; billingSubsidiary: string }[] }>(
        `${environment.api}/v1/internal_or_external_dispatcher/list_companies`,
      )
      .pipe(
        map((results) => results.companies),
        shareReplay(1),
      );
  }

  public switchCarrier(companyId: string) {
    return lastValueFrom(this.httpClient.post(`${environment.api}/v1/internal/swap_company`, { companyId }));
  }
}
