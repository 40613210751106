<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
  <div *ngIf="breadcrumbs.length" class="flex items-center">
    <div class="flex items-center" @fadeIn *ngFor="let bc of breadcrumbs; trackBy: trackByFn">
      <mat-icon class="text-gray-300">chevron_right</mat-icon>
      <div class="text-gray-400 px-1 leading-[24px]">
        <div *ngIf="!bc.url?.length">{{ bc.label }}</div>
        <a *ngIf="bc.url?.length" [routerLink]="bc.url">{{ bc.label }}</a>
      </div>
    </div>
  </div>
</ng-container>
