import Fuse from 'fuse.js';

type FuseReturn<T> = (tArray: T[]) => { data: T[]; fuse: Fuse<T> };

export const fuse =
  <T>(searchOptions: Fuse.IFuseOptions<T>): FuseReturn<T> =>
  (tArray: T[]) => {
    return fuseWithOptions(tArray, searchOptions);
  };

const fuseWithOptions = <T>(tArray: T[], searchOptions: Fuse.IFuseOptions<T>) => ({
  data: tArray,
  fuse: new Fuse(tArray, searchOptions),
});
